import { useContext } from 'react'

import { ShopContext } from '@lib/shop'
import InputSearchDropdown, {
  StyleOverrides,
} from '@components/input-search-dropdown'
import InputDropdown from '@components/input-dropdown'

interface PrintShopSelectProps {
  label?: string
  placeholder?: string
  hasBorder?: boolean
  useNativeSelect?: boolean
  className?: string
  styles?: StyleOverrides
}

const PrintShopSelect = ({
  label,
  placeholder,
  hasBorder,
  useNativeSelect,
  className,
  styles,
}: PrintShopSelectProps) => {
  const { printShops, printShop, setPrintShop } = useContext(ShopContext)

  const handleChange = (value: string) => {
    const printShop = printShops.find((shop) => shop.id === value)

    if (!printShop) {
      return
    }

    setPrintShop(printShop)
  }

  const sortedOptions = printShops
    .filter(({ active }) => active)
    .map(({ id, name }) => ({ value: id, label: name }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return useNativeSelect ? (
    <InputDropdown
      className={className}
      id="print-shop-select"
      options={sortedOptions}
      defaultValue={printShop?.id ?? ''}
      placeholder={placeholder}
      hasBorder={hasBorder}
      onChange={handleChange}
      onBlur={handleChange}
      label={label}
    />
  ) : (
    <InputSearchDropdown
      id="print-shop-select"
      label={label}
      className={className}
      placeholder={placeholder}
      onChange={handleChange}
      value={printShop?.id ?? ''}
      options={sortedOptions}
      hasBorder={hasBorder}
      styles={styles}
    />
  )
}

export default PrintShopSelect
